import { clsx } from 'clsx';

import type { CommonLayoutProps } from './type';

import { IconChevronLeft } from '~/components/icons';

import { TITLEBAR_HEIGHT, CONTAINER_WIDTH } from './const';

export const CommonLayout = ({
  onClickLeft,
  children,
  isWidthFixed = true,
}: CommonLayoutProps) => {
  return (
    <div className={clsx('tw-flex tw-min-h-dvh tw-flex-col')}>
      <header
        style={{
          height: `${TITLEBAR_HEIGHT}px`,
        }}
        className={clsx(
          'tw-flex tw-items-center tw-justify-center',
          'tw-relative tw-bg-white tw-p-2 tw-text-center tw-text-ui18 tw-font-light tw-text-black tw-shadow-[0_4px_8px_0_rgba(0,0,0,0.05)]'
        )}
      >
        <div
          className={clsx(
            'tw-absolute tw-left-2 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center'
          )}
        >
          {onClickLeft !== undefined && (
            <button type="button" onClick={onClickLeft}>
              <IconChevronLeft />
            </button>
          )}
        </div>
        お届け状況の確認
      </header>
      <div
        style={{
          height: `calc(100dvh - ${TITLEBAR_HEIGHT}px)`,
        }}
        className={clsx('tw-flex tw-justify-center', 'tw-bg-gray-50')}
      >
        <main
          style={{
            width: isWidthFixed ? `${CONTAINER_WIDTH}px` : '100%',
          }}
          className="tw-overflow-auto"
        >
          {children}
        </main>
      </div>
    </div>
  );
};
